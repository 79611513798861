import { Editor, Transforms } from 'slate';

import type { Normalizer } from '@ui/MarkdownEditor/types';

import { isParagraph } from './shared';

const unnest: Normalizer =
  next =>
  // eslint-disable-next-line consistent-return
  (editor, [node, path]) => {
    if (!isParagraph(node) || !Editor.hasBlocks(editor, node)) return next();

    Transforms.unwrapNodes(editor, { at: path, match: isParagraph });
  };

export default [unnest];
