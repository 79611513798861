/* eslint-disable consistent-return */
import type { NodeEntry, RangeRef } from 'slate';

import { Editor, Node, Path, Text, Transforms } from 'slate';

import * as blocks from '@ui/MarkdownEditor/editor/blocks';
import { JsxComment, TableCell } from '@ui/MarkdownEditor/editor/blocks';
import { DIRTY_NODES_DECORATE } from '@ui/MarkdownEditor/editor/cache';
import { contains, queueHandlers } from '@ui/MarkdownEditor/editor/utils';
import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { Normalizer, ParagraphElement, ReadmeEditor } from '@ui/MarkdownEditor/types';

const normalizeNewlines: Normalizer =
  next =>
  (editor, [node, path]) => {
    if (
      !Text.isText(node) ||
      (!editor.props.useMDX && Editor.above(editor, { at: path, match: TableCell.isTableCell })) ||
      Editor.above(editor, { at: path, match: n => JsxComment.is(n) })
    ) {
      return next();
    }

    // @note: This should match 2 newlines with any amount of non-newline whitespace between them
    const match = node.text.match(/\n[ \f\t\v\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]*\n/);
    if (!match || typeof match.index === 'undefined') return next();

    const atRef: RangeRef = Editor.rangeRef(editor, {
      anchor: { path, offset: match.index },
      focus: { path, offset: match.index + match[0].length },
    });
    const select = editor.selection && contains(atRef.current, editor.selection);

    try {
      Editor.withoutNormalizing(editor, () => {
        if (!atRef.current) return;
        const parentPath = Path.parent(path);

        if (
          !Editor.isBlock(editor, Node.get(editor, parentPath)) ||
          TableCell.isTableCell(Node.get(editor, parentPath))
        ) {
          Transforms.wrapNodes(editor, { type: 'paragraph' } as ParagraphElement, {
            at: Editor.range(editor, path),
            match: n => !Editor.isBlock(editor, n),
          });
        } else {
          Transforms.insertNodes(editor, [emptyNode()], { at: atRef.current, select: true });
        }
      });
    } finally {
      atRef.unref();
    }

    if (select) {
      const positions = Editor.positions(editor);
      positions.next();
      const nextPosition = positions.next();

      if (!nextPosition.done) {
        Transforms.select(editor, nextPosition.value);
      }
    }
  };

const dontAllowEmpty: Normalizer =
  next =>
  (editor, [node]) => {
    if (!Editor.isEditor(node)) return next();
    if (node.children.length > 0) return next();

    Transforms.insertNodes(editor, emptyNode());
  };

const markDirty = (_: Editor, [node]: NodeEntry) => {
  DIRTY_NODES_DECORATE.set(node, true);
};

const normalizeNode = (editor: ReadmeEditor) => {
  const { normalizeNode: baseNormalizeNode } = editor;
  const baseHandler: Normalizer = () => (_, nodeEntry) => baseNormalizeNode(nodeEntry);
  const handlers = Object.values(blocks).reduce(
    (acc: Normalizer[], block) => ('normalizeNode' in block ? acc.concat(block.normalizeNode) : acc),
    [],
  );
  const readmeNormalizeNode: (e: ReadmeEditor, nodeEntry: NodeEntry) => void = queueHandlers(
    ...handlers,
    normalizeNewlines,
    dontAllowEmpty,
    baseHandler,
  );

  return (nodeEntry: NodeEntry) => {
    markDirty(editor, nodeEntry);

    readmeNormalizeNode(editor, nodeEntry);
  };
};

export default normalizeNode;
