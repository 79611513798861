import isEqual from 'lodash/isEqual';
import { Editor, Transforms, Node, Path } from 'slate';

import emptyNode from '@ui/MarkdownEditor/emptyNode';
import type { OnKeyDown } from '@ui/MarkdownEditor/types';

import { CodeTabs, Html, JsxFlow } from '../blocks';
import { offsetToCodeEditorSelection } from '../utils';

// When backspacing into a code tabs node, we want to focus within it instead of deleting it
const backspaceIntoVoidNodes: OnKeyDown = (event, editor) => {
  const selection = editor.selection;
  if (!selection) return;

  if (
    !(
      event.key === 'Backspace' &&
      selection.anchor.offset === 0 &&
      selection.focus.offset === 0 &&
      Path.hasPrevious(Path.parent(selection.anchor.path)) &&
      [CodeTabs, Html, JsxFlow].find(block =>
        block.is(Node.get(editor, Path.previous(Path.parent(selection.anchor.path)))),
      )
    )
  )
    return;

  event.preventDefault();
  event.stopPropagation();

  const currentPath = Path.parent(selection.anchor.path);
  const previousNode = Node.get(editor, Path.previous(currentPath));
  // @ts-expect-error - validated previously
  const value = CodeTabs.is(previousNode) ? previousNode.tabs[previousNode.active].value : previousNode.value;
  const codeEditorSelection = [offsetToCodeEditorSelection(value.length, value)] as [CodeMirror.Position];

  Editor.withoutNormalizing(editor, () => {
    if (isEqual(emptyNode(), Node.get(editor, currentPath))) {
      Transforms.delete(editor, { at: currentPath });
    }
    Transforms.select(editor, { path: Path.previous(currentPath), offset: 0 });
    Transforms.setNodes(editor, { selection: codeEditorSelection }, { at: Path.previous(currentPath) });
  });
};

export default backspaceIntoVoidNodes;
