import type { RowItems, PagesInfo, PageUsageMenuProps } from './types';
import type {
  CustomBlockPageCollectionType,
  CustomBlockPageRepresentationType,
} from '@readme/api/src/mappings/customblock/page/types';
import type { ComponentProps } from 'react';

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { VariableSizeList as VirtualList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useFuzzySearch from '@core/hooks/useFuzzySearch';
import { useReadmeApiInfinite } from '@core/hooks/useReadmeApi';
import { superHubStore, useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';
import Spinner from '@ui/Spinner';

import classes from './index.module.scss';

// Number of items to fetch per page
const PER_PAGE = 10;

// Height dimension in pixels of each item in the list
const MENU_ITEM_SIZE = 30;
const MENU_HEADER_SIZE = 26;

// Number of items to display in the menu before scrolling
const MENU_ITEMS_IN_VIEW = 5;

// Number of rows remaining before triggering data loading on scroll
const ROW_THRESHOLD = 3;

/**
 * When we use the `useReadmeApiInfinite` hook to fetch Custom Blocks,
 * we get back an array of pages in `state.data`. This function will flatten that
 * array into a single array of items.
 */
export const transformDataToItems = (
  data: ReturnType<typeof useReadmeApiInfinite<CustomBlockPageCollectionType>>['data'],
  showProjectUsage: boolean,
  isGlobal: boolean,
) => {
  const { isSuperHub, isEditing } = superHubStore.getState();
  const items: PagesInfo = {
    pages: data?.flatMap(res => res?.data || ([] as CustomBlockPageRepresentationType[])) || [],
    projects: [],
    paging: data?.flatMap(res => res?.paging || ({} as PagesInfo['paging'])) || {},
  };
  const rows: RowItems[] = [];

  if (!showProjectUsage) {
    rows.push({
      name: isGlobal ? 'In This Project' : 'Using In',
      type: 'header',
    });
  }

  items.pages.forEach((p: CustomBlockPageRepresentationType) => {
    if (p.project) {
      if (showProjectUsage && !items.projects.includes(p.project.name)) {
        items.projects.push(p.project.name);
        rows.push({
          name: p.project.name,
          type: 'header',
        });
      }
      const regex = /(\d+(\.\d)*)/;
      const versionArr = p.uri?.match(regex) as string[];
      const href = isSuperHub ? `${isEditing ? '/update/' : '/'}${p.section}/${p.slug}` : p.href.dash;

      rows.push({
        name: p.title,
        href,
        project: p.project,
        version: versionArr[0],
        type: 'item',
      });
    }
  });
  return { items, rows };
};

const PageUsageMenu = ({ blockId, isGlobal, usageData, showProjectUsage }: PageUsageMenuProps) => {
  const bem = useClassy(classes, 'PageUsageMenu');
  const [isSuperHub, isSuperHubEditLayout] = useSuperHubStore(s => [s.isSuperHub, s.layout === 'edit']);
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const isGroup = !!project?.childrenProjects?.length;
  const subdomain = project.subdomain;
  const { version } = useContext(VersionContext);
  const versionParam = isGroup ? 'stable' : version;
  const pageCount = usageData?.pageCount || 0;
  const projectCount = usageData?.projectCount || 0;

  const { data, isLoading, size, setSize, isValidating } = useReadmeApiInfinite<CustomBlockPageCollectionType>(
    (pageIndex, previousPageData) => {
      if (previousPageData?.paging?.next === null) {
        // Reached the end of the list
        return null;
      }
      return `/${subdomain}/api-next/v2/versions/${versionParam}/custom_blocks/${blockId}/pages?page=${
        pageIndex + 1
      }&per_page=${PER_PAGE}`;
    },
  );

  const { items, rows } = useMemo(() => {
    return transformDataToItems(data, showProjectUsage, isGlobal);
  }, [data, showProjectUsage, isGlobal]);

  // Set up search filter
  const [pageFilter, setPageFilter] = useState('');
  const allPages = rows.filter(row => row.type === 'item');
  const headers = rows.filter(row => row.type === 'header');
  const [filteredPages] = useFuzzySearch(allPages, pageFilter, { key: 'name', highlight: false });

  // Order menu by header and filtered pages
  const groupFilteredPages = (pages: RowItems[]) => {
    if (isGroup) {
      const group: RowItems[] = [];
      pages.forEach(page => {
        headers.forEach(header => {
          if (!group.includes(header) && header.name === page.project?.name) group.push(header);
        });
        group.push(page);
      });
      return group;
    }
    return headers.concat(filteredPages);
  };

  const filteredHeaderedPages = groupFilteredPages(filteredPages);

  const onSearchChange = useCallback(e => {
    setPageFilter(e.target.value);
  }, []);

  const [hasNextPage] = useMemo(() => {
    if (!data) return [false, 0, 0];
    const pagesData = data[data.length - 1];
    const _hasNextPage = pagesData.paging.next !== null;
    return [_hasNextPage];
  }, [data]);

  const itemCount = hasNextPage ? filteredHeaderedPages.length + 1 : filteredHeaderedPages.length;
  const loadMoreItems = isValidating ? () => {} : () => setSize(size + 1);

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.pages.length,
    [hasNextPage, items.pages.length],
  );

  const getItemSize = (index: number) => {
    if (rows[index]?.type === 'header') return MENU_HEADER_SIZE;
    return MENU_ITEM_SIZE;
  };

  const listHeight = useMemo(() => {
    if (!filteredHeaderedPages?.length) return 0;

    const getListHeight = (listItems: RowItems[]) => {
      return listItems.reduce((acc, row) => {
        if (row.type === 'header') {
          return acc + MENU_HEADER_SIZE;
        }
        return acc + MENU_ITEM_SIZE;
      }, 0);
    };

    return filteredHeaderedPages.length >= MENU_ITEMS_IN_VIEW + 1
      ? getListHeight(filteredHeaderedPages.slice(0, MENU_ITEMS_IN_VIEW)) + MENU_ITEM_SIZE / 2 // Add half an item to the height to allow for scrolling affordance
      : getListHeight(filteredHeaderedPages);
  }, [filteredHeaderedPages]);

  const s = (count: number) => (count !== 1 ? 's' : '');
  const label = `Using in ${pageCount} page${s(pageCount)}${
    showProjectUsage && projectCount > 1 ? ` / ${projectCount} project${s(projectCount)}` : ''
  }`;

  return (
    <div className={bem('&')}>
      {pageCount === 0 ? (
        <Button className={bem('-label')} contentEditable={false} kind="secondary" size="xs" text>
          {label}
        </Button>
      ) : (
        <Dropdown appendTo={() => document.body} justify="end" offset={[-10, -5]} sticky>
          <Button className={bem('-label')} contentEditable={false} kind="secondary" size="xs" text>
            {label}
            <Icon name="chevron-down" size={14} strokeWeight={2.5} />
          </Button>
          <Menu className={bem('-menu')} contentEditable={false}>
            {pageCount > 4 && (
              <Flex className={bem('-menu-search')}>
                <Input
                  className={bem('-menu-search-input')}
                  onChange={onSearchChange}
                  onClear={() => setPageFilter('')}
                  placeholder="Search Pages"
                  prefix={<Icon name="search" />}
                  size="sm"
                />
              </Flex>
            )}
            {isLoading ? (
              <Spinner className={bem('-loading-spinner')} strokeWidth={1.5} />
            ) : !filteredPages.length ? (
              <div className={bem('-menu-results_empty')}>
                <p>No results</p>
              </div>
            ) : (
              <>
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                  threshold={ROW_THRESHOLD}
                >
                  {({ onItemsRendered, ref }) => {
                    return (
                      <VirtualList
                        ref={ref}
                        height={listHeight}
                        itemCount={itemCount}
                        itemData={filteredHeaderedPages}
                        itemSize={getItemSize}
                        onItemsRendered={onItemsRendered}
                        width="100%"
                      >
                        {({ index, style }) => {
                          if (!isItemLoaded(index) && !isItemLoaded(index - 1)) {
                            return (
                              <MenuItem key={index} style={style}>
                                <Spinner size="sm" />
                              </MenuItem>
                            );
                          }

                          const row = filteredHeaderedPages[index];

                          if (row.type === 'header') {
                            return (
                              <div style={style}>
                                <MenuHeader key={index}>{row.name}</MenuHeader>
                              </div>
                            );
                          }

                          const isPageInThisProject = row.project?.subdomain === subdomain;

                          const menuItemProps: Partial<ComponentProps<typeof MenuItem>> =
                            // If we're in the SuperHub and in edit mode, we want to use the Link component
                            // to navigate to the page with the browser router. Otherwise, we'll use a standard
                            // anchor tag to open the page with a wholesale reload.
                            isPageInThisProject && isSuperHub && isSuperHubEditLayout
                              ? {
                                  to: row.href,
                                  TagName: Link,
                                }
                              : {
                                  href: row.href,
                                  target: isPageInThisProject ? '_self' : '_blank',
                                  TagName: 'a',
                                };

                          return (
                            <div style={style}>
                              <MenuItem key={index} icon={<Icon name="file" strokeWeight={3} />} {...menuItemProps}>
                                <div className={bem('-menu-results-label')}>
                                  <span className={bem('-menu-results-name')}>{row.name}</span>
                                  {!isGroup && row.href === window.location.href && (
                                    <span className={bem('-menu-results-current')}>THIS PAGE</span>
                                  )}
                                  {!!isGroup && (
                                    <span className={bem('-menu-results-version')}>{`v${row.version}`}</span>
                                  )}
                                </div>
                              </MenuItem>
                            </div>
                          );
                        }}
                      </VirtualList>
                    );
                  }}
                </InfiniteLoader>
                <MenuDivider />
                <MenuItem className={bem('-menu-note')} focusable={false}>
                  Any changes made here will be reflected on pages above after saving
                </MenuItem>
              </>
            )}
          </Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default PageUsageMenu;
