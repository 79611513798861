import type { HTMLAttributes } from 'react';

import isEqual from 'lodash/isEqual';
import React, { useCallback } from 'react';
import { Editor, Node, Path, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import type { ReadmeEditor } from '@ui/MarkdownEditor/types';

import emptyNode from './emptyNode';
import classes from './style.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  forwardedRef?: React.MutableRefObject<HTMLDivElement>;
  style?: React.CSSProperties;
}

const Container = ({ forwardedRef, children, style, ...props }: Props) => {
  const editor = useSlateStatic() as ReadmeEditor;

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      const path = [editor.children.length - 1];
      const node = Node.get(editor, path);

      if (!isEqual(node, emptyNode())) {
        Transforms.insertNodes(editor, emptyNode(), { at: Path.next(path) });
      }

      Transforms.select(editor, Editor.end(editor, []));
      ReactEditor.focus(editor);
    },
    [editor],
  );

  return (
    <div ref={forwardedRef} {...props} className={classes.Container} role="none" style={style}>
      {children}
      <button
        aria-label="Focus on editor"
        className={classes.Container_Button}
        data-testid="editor-container-button"
        onClick={onClick}
        tabIndex={-1}
      />
    </div>
  );
};

export default React.memo(
  React.forwardRef<HTMLDivElement, Props>((props, ref) => (
    <Container {...props} forwardedRef={ref as React.MutableRefObject<HTMLDivElement>} />
  )),
);
