import type { Editor } from 'slate';

import React, { createContext, useContext, useMemo, useState } from 'react';

interface CustomBlockFormContextValue {
  editor: Editor | null;
  setEditor: (editor: Editor) => void;
}

const EditorContext = createContext<CustomBlockFormContextValue>({
  editor: null,
  setEditor: () => {},
} as CustomBlockFormContextValue);

export default function CustomBlocksEditorContext({ children }: React.PropsWithChildren<unknown>) {
  const [editor, setEditor] = useState<Editor | null>(null);

  const value = useMemo(() => ({ editor, setEditor }), [editor, setEditor]);

  return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
}

export const useCustomBlockEditorContext = () => useContext(EditorContext);
