import { Transforms, Node, Path } from 'slate';

import { isCodeTabs } from './shared';

const arrowUp = (event, editor) => {
  if (!(event.key === 'ArrowUp' && !event.shiftKey)) return;

  const rootPath = editor.selection.anchor.path.slice(0, 1);
  if (!Path.hasPrevious(rootPath)) return;

  const previousPath = Path.previous(rootPath);
  const previousNode = Node.get(editor, previousPath);
  if (!isCodeTabs(previousNode)) return;

  Transforms.select(editor, previousPath);
};

const arrowDown = (event, editor) => {
  if (!(event.key === 'ArrowDown' && !event.shiftKey)) return;

  const rootPath = editor.selection.anchor.path.slice(0, 1);
  const nextPath = Path.next(rootPath);
  if (!Node.has(editor, nextPath)) return;

  const nextNode = Node.get(editor, nextPath);
  if (!isCodeTabs(nextNode)) return;

  Transforms.select(editor, nextPath);
};

export default [arrowUp, arrowDown];
