import React from 'react';

import useClassy from '@core/hooks/useClassy';

import CodeInput from '@ui/CodeInput';
import { RHFGroup } from '@ui/RHF';
import Tabs from '@ui/Tabs';

import { useCustomBlockFormContext } from '../Context';

import classes from './style.module.scss';

export default function ComponentEditor() {
  const bem = useClassy(classes, 'ComponentEditor');
  const { control } = useCustomBlockFormContext();

  return (
    <div className={bem('&')} data-testid="ComponentEditor">
      <Tabs
        className={{
          root: bem('-tabs'),
          tabs: bem('-tabs-list'),
        }}
      >
        <div data-label="index.jsx">
          <RHFGroup control={control} name="source" required>
            {({ field }) => (
              <CodeInput {...field} className={bem('-codeInput')} initialValue={field.value} language="jsx" />
            )}
          </RHFGroup>
        </div>
        <div data-label="style.css">
          <RHFGroup control={control} name="css">
            {({ field }) => (
              <CodeInput {...field} className={bem('-codeInput')} initialValue={field.value} language="css" />
            )}
          </RHFGroup>
        </div>
      </Tabs>
    </div>
  );
}
