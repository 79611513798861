import type { CodeEditorSelection } from '../../CodeEditor';
import type { BaseSelection } from 'slate';

import { Editor, Node, Text, Transforms } from 'slate';

import { isEmptyNode } from '@ui/MarkdownEditor/emptyNode';
import type { JsxFlowElement } from '@ui/MarkdownEditor/types';

import { offsetToCodeEditorSelection, offsetToPoint, pointToOffset } from '../../utils';

import { isJsxFlowElement, type } from './shared';

interface Opts {
  at?: BaseSelection;
  codeEditorSelection?: CodeEditorSelection;
}

export const insertJsxFlow = (
  editor: Editor,
  value: string,
  { at = editor.selection, codeEditorSelection }: Opts = {},
) => {
  if (!at) throw new Error('Cannot insert JsxFlowElement without a location');

  const textEntry = Editor.node(editor, at.anchor.path);

  if (!Text.isText(textEntry[0])) {
    throw new Error('Cannot insert JsxFlowElement into non-text node');
  }

  if (editor.selection && !codeEditorSelection) {
    // eslint-disable-next-line no-param-reassign
    codeEditorSelection = [
      offsetToCodeEditorSelection(pointToOffset(textEntry, editor.selection.anchor), textEntry[0].text),
      offsetToCodeEditorSelection(pointToOffset(textEntry, editor.selection.focus), textEntry[0].text),
    ];
  }

  const jsxFlow: JsxFlowElement = { type, value, children: [{ text: '' }], selection: codeEditorSelection };

  Editor.withoutNormalizing(editor, () => {
    Transforms.insertNodes(editor, jsxFlow, { at, select: true });

    const parent = Editor.above(editor, { at, match: n => Editor.isBlock(editor, n) && !isJsxFlowElement(n) });
    if (parent) {
      /* @note: Deleting/splitting the parent node always leaves a text
       * leaves at the front. To the user, this would plop the new Jsx
       * Element on the next line 🤮. So, we need to remove the previous
       * parent node if it's empty.
       *
       * It might not be empty if the Jsx tag started on a newline, instead
       * of the beginning of a paragraph.
       */
      if (isEmptyNode(parent[0])) {
        Transforms.removeNodes(editor, { at: parent[1] });
      } else {
        const string = Node.string(parent[0]);
        /* @note: If the parent node ends with a newline, then we need to
         * remove it. Otherwise, the Jsx Element will appear to got plopped
         * into the next line.
         */
        if (string.match(/\n$/)) {
          Transforms.delete(editor, { at: offsetToPoint(parent, string.length - 1) });
        }
      }
    }
  });
};
